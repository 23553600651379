<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo style="height: 4em;" />
        </b-link>

        <b-card-text class="mb-2 text-center">
          Please sign in to your account
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginValidation" #default="{invalid}">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-overlay :show="show" rounded="sm" opacity="0.2" no-fade>
              <b-form-group label-for="email" label="Username">
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <v-select
                    v-model="userName"
                    :options="users"
                    :clearable="false"
                    placeholder="Select User"
                    :reduce="(val) => val"
                    
                     
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="login-password"
                      placeholder="Password"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <!-- We add an SR only text for screen readers -->
                  <span class="sr-only">Please wait...</span>
                </div>
              </template>

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                @click="login"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-overlay>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BSpinner,
  BOverlay,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
import router from "@/router";
import useJwt from "@/auth/jwt/useJwt";
import { mapState, mapActions } from "vuex";
import vSelect from "vue-select";

export default {
  components: {
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  mixins: [togglePasswordVisibility],
  mounted() {
    localStorage.removeItem('userData')
    localStorage.removeItem('userPerms')
    this.getUserNames();
  },
  data() {
    return {
      status: "",
      password: "",
      userName: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      show: false,
      users: []
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    validPerm: {
      get() {
        return this.$store.getters["validPermissions"];
      },
      set(newValue) {
        return this.$store.dispatch("setValidPermission", newValue);
      },
    }
  },
  methods: {
    ...mapActions(["getTeamNames","setUser","setValidPermission"]),
    getUserNames() {
      this.$store.dispatch("getUserNames").then((res) => {
        if(!res.data.check_ip && res.data.message == 'This ip address is inaccessible'){
          
          router.push({ path: '/inaccessable-ip-adress' });
        } else {
          if(res.data.users.length > 0){
          this.users = res.data.users
        }
        }
        
      });
    },

    login() {
            this.$refs.loginValidation.validate().then((success) => {
                if (success) {
                    this.show = true;
                    useJwt
                        .login({
                            email: this.userName,
                            password: this.password,
                        })
                        .then((response) => {

                            if (response.data.error == "Unauthorized") {
                                this.$swal({
                                    title: "Authorization Error",
                                    text: "Incorrect login details, please try again.",
                                    icon: "error",
                                    showCancelButton: false,
                                    confirmButtonText: "OK",
                                });

                                this.show = false;
                            } else {
                                const { userData } = response.data;

                                useJwt.setToken(response.data.accessToken);
                                useJwt.setRefreshToken(response.data.refreshToken);
                                localStorage.setItem("userData", JSON.stringify(userData));
                                localStorage.setItem("userPerms", JSON.stringify(response.data.userPerms));

                                // set vuex userdata
                                this.setUser(userData)
                                this.setValidPermission(response.data.userPerms)

                                /* this.$ability.update(userData.ability);*/

                                this.$toast({
                                    component: ToastificationContent,
                                    position: "top-right",
                                    props: {
                                        title: `Welcome ${userData.name || userData.surname}`,
                                        icon: "CoffeeIcon",
                                        variant: "success",
                                        text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                                    },
                                });

                                this.show = false;
                                router.push({ path: "/" });
                            }
                        })
                        .catch((error) => {
                            this.$swal({
                                title: "Login Error",
                                text: "Error occurred while logging in, please try again or contact support",
                                icon: "error",
                                showCancelButton: false,
                                confirmButtonText: "OK",
                            });

                            this.show = false;
                            this.$refs.loginValidation.setErrors(error.response.data.error);
                        });
                }
            });
        },

    // login() {
    //   this.$refs.loginValidation.validate().then((success) => {
    //     if (success) {
    //       this.show = true;
    //       useJwt
    //         .login({
    //           email: this.userName,
    //           password: this.password,
    //         })
    //         .then((response) => {
    //           let res = response.data[0];

    //           if (res.accesslevel == "Fail") {
    //             this.$toast({
    //               component: ToastificationContent,
    //               position: "top-right",
    //               props: {
    //                 title: `Error`,
    //                 icon: "CoffeeIcon",
    //                 variant: "danger",
    //                 text: `Incorrect login details, please try again.`,
    //               },
    //             });
    //             this.show = false;
    //           } else {
    //             //set store in state user with encrypted password
    //             this.user = {
    //               name: this.userName,
    //               password: this.$encryptPassword(this.password),
    //             };

    //             //take return user's access levels  after enter pass and username
    //             let accessLevels = res.accesslevel.split(",");


    //             //get Team Names with vuex store
    //             this.$store.dispatch("getTeamNames", this.user).then((res) => {
    //               let validPerm = [];
    //               let logged = "";
    //               let first = true
                  
    //               if (accessLevels) {
    //                 accessLevels.forEach((level) => {
    //                   if (res.data.length > 0) {
    //                     res.data.forEach((t, i) => {
    //                       if (t.AccessLevel == level) {
    //                         if(first) {
    //                           logged = t.TeamName;
    //                           first = false
    //                         } else {
    //                           first = false
    //                           logged += ", " + t.TeamName;
    //                         }
    //                         validPerm.push(t);
    //                       }
    //                     });
    //                   }
    //                 });
    //               }

    //               if (validPerm.length > 0) {
    //                 const userData = {
    //                   name: this.user.name,
    //                   role: validPerm[0].TeamName ?? 'Viewing'
    //                 }
    //                 this.user.role = validPerm[0].TeamName ?? 'Viewing'
    //                 this.validPerm = validPerm

    //                 localStorage.setItem('userData',JSON.stringify(userData))
    //                 localStorage.setItem('userPerms',JSON.stringify(validPerm))

    //                 this.$toast({
    //                   component: ToastificationContent,
    //                   position: "top-right",
    //                   props: {
    //                     timeout: 5000,
    //                     title: `Welcome ${this.user.name}`,
    //                     icon: "CoffeeIcon",
    //                     variant: "success",
    //                     text: `You logged in as ${logged}. Now you can start to explore!`,
    //                   },
    //                 });

    //                 this.show = false;
    //                 router.push({ path: "/" });
    //               }
    //             });
    //           }
    //         })
    //       .catch((error) => {
    //         this.$toast({
    //           component: ToastificationContent,
    //           position: "top-right",
    //           props: {
    //             title: `Error`,
    //             icon: "CoffeeIcon",
    //             variant: "danger",
    //             text: `Error occurred while logging in`,
    //           },
    //         });
    //         this.show = false;
    //         //this.$refs.loginValidation.setErrors(error.response.data.error);
    //       });
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
